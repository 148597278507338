.errors-list {
  margin: 0;
  padding: 0;

  li {
    list-style-type: none;
    margin: 5px 0;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}
