.accreditation {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;

  @include respond-to(tablet) {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 30px;
  }

  @include respond-to(macbook) {
    grid-template-columns: repeat(4, 1fr);
  }

  @include respond-to(desktop) {
    grid-template-columns: repeat(5, 1fr);
  }

  &__item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 157px;
    background-color: $color-grey;
    border-radius: 10px;
    transition: all $transition-time ease-out;
    overflow: hidden;

    @include respond-to(tablet) {
      height: 216px;
    }

    &:hover {
      box-shadow: 0 1px 12px 0 rgba($color-black, 0.15);
    }

    span {
      svg,
      img {
        max-height: 85px;

        @include respond-to(tablet) {
          max-height: 110px;
        }
      }
    }
  }
}
