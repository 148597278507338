.form {
  display: flex;
  flex-direction: column;

  &.ant-form {
    color: $color-black;
  }

  .ant-form-item {
    margin: 0 0 20px;

    @include respond-to(tablet) {
      margin: 0 0 30px;
    }
  }

  &__item {
    position: relative;
    min-width: 0;
  }

  .ant-input {
    height: 40px;
    border: 1px solid $color-main;
    border-radius: 10px;
    padding: 0 15px;
    background: transparent;

    font-size: 16px;
    color: $color-black;
    font-weight: 600;

    &:focus,
    &:hover {
      border-color: $color-main;
      border-width: 1px !important;
      box-shadow: none;
    }

    &::placeholder {
      color: $color-placeholder;
    }

    &-affix-wrapper {
      border: 1px solid $color-black;
      height: 40px;
      border-radius: 10px;
      padding: 0 12px 0 11px;
      box-shadow: none;
      background: transparent;

      &:focus,
      &:hover {
        border-color: $color-main;
        border-width: 1px !important;
        box-shadow: none;
        background: transparent;
      }
    }

    &-prefix {
      margin-right: 0;

      .anticon {
        width: 16px;
        height: 16px;
        margin: 0 8px 0 0;

        svg {
          width: 16px;
          height: 16px;
        }
      }

      &-text {
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: 600;
        color: $color-black;
      }
    }
  }

  .ant-input-affix-wrapper {
    .ant-input {
      border: none;
      padding: 0;
      height: 38px;
      background: transparent;
    }
  }

  .ant-form-item {
    .ant-form-item-label {
      margin: 0 0 10px;
      padding: 0;

      white-space: nowrap;
    }
  }

  .ant-form-item-label {
    text-align: left;
    margin: 0 0 10px;

    > label {
      font-size: 20px;
      line-height: 27px;
      font-weight: 700;

      &:after {
        display: none;
      }
    }

    > label.ant-form-item-required {
      &::before {
        display: none !important;
      }
    }
  }

  &.ant-form-vertical {
    .ant-form-item-label {
      flex-basis: auto;
    }
  }

  .ant-form-item-explain {
    color: $color-main;
    line-height: 18px;
    font-weight: 700;
    text-align: center;
    white-space: nowrap;

    @include respond-to(tablet) {
      margin-top: 1px;
    }

    @include respond-to(desktop) {
      margin-top: 3px;
    }

    &-error {
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .ant-form-item-has-error {
    .ant-input {
      border-color: $color-main;

      &:not(disabled):focus,
      &:not(disabled):hover,
      &:focus,
      &:hover {
        box-shadow: none;
        border-color: $color-red;
        border-width: 2px;
      }
    }

    .ant-form-item-explain {
      color: $color-red;
    }
  }

  .btn + .btn {
    margin: 20px 0 0;
  }

  &__button-wrap {
    display: flex;
    justify-content: center;
    gap: 20px;
    grid-column: 1 / 3;
  }

  &__button-link {
    justify-self: center;
    grid-column: 1 / 3;
  }

  &__label-title {
    font-size: 16px;
    line-height: 1.25;
    font-weight: 700;
    margin: 0 0 10px;
  }

  &__search-field.ant-input-search {
    .ant-input-affix-wrapper {
      border: 2px solid $color-main;
      border-right-width: 0;
      padding: 0 13px 1px;

      &:hover,
      &:focus {
        border-width: 2px !important;
        border-right-width: 0 !important;
      }

      &:not(:last-child) {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
      }

      .ant-input {
        padding-bottom: 3px;
      }
    }

    & > .ant-input-group > .ant-input-group-addon .ant-input-search-button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      border-color: $color-main;
      background: $color-main;
      box-shadow: none;
      border-radius: 0 10px 10px 0;
      padding: 0;

      &:hover {
        border-color: $color-main-hover;
        background: $color-main-hover;
      }

      span,
      svg {
        width: 20px;
        height: 20px;
      }

      svg {
        fill: $color-white;
      }
    }
  }
}

.ant-input-group-addon {
  background-color: transparent;
}
