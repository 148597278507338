.ant-spin {
  display: inline-flex;

  &.content-preloader {
    margin: auto;
    height: 100vh;

    display: flex;
    align-items: center;
    justify-content: center;

    .page & {
      display: flex;
    }
  }

  .content > & {
    margin: auto;
  }

  .ant-spin-dot-item {
    background: $color-main;
  }
}

.ant-spin-holder.ant-spin-holder {
  display: flex;
  justify-content: center;
}

.ant-spin-spinning {
  display: inline-block;
  line-height: 1;
  margin: -5px 0 -1px;
  transform: translateY(2px);
}
