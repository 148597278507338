.wysiwyg {
  font-size: 14px;
  line-height: 1.875;

  @include respond-to(tablet) {
    font-size: 16px;
    line-height: 1.625;
  }

  &__title {
    margin: 0 0 30px;
  }

  &.page__text {
    max-width: 100%;

    > * {
      @include respond-to(macbook) {
        max-width: 1200px;
        margin-left: auto !important;
        margin-right: auto !important;
        width: 100%;
      }
    }
  }

  p,
  h2:not(.title),
  h3:not(.title),
  blockquote,
  figure,
  ol,
  li {
    margin: 20px 0;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__button-block {
    &-container {
      display: flex;
      justify-content: center;
      margin: 20px 0 0;

      a.ant-btn {
        text-decoration: none;
        line-height: 36px;
      }
    }
  }

  h2:not(.title) {
    font-size: 16px;
    line-height: 1.25;
    font-weight: 700;
    text-transform: uppercase;
    margin: 30px 0 20px 0;

    @include respond-to(tablet) {
      font-size: 20px;
      line-height: 1.35;
      margin: 40px 0 30px;
    }
  }

  h3 {
    margin: 20px 0;
    text-transform: none;
    font-size: 20px;
    line-height: 32px;
    font-weight: 700;

    @media (max-width: 767px) {
      font-size: 16px;
    }
  }

  .video {
    position: relative;
    max-width: 100%;
    height: auto;
    overflow: hidden;
    padding: 0 0 56.25%;

    embed,
    iframe,
    object {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }

  ul,
  ol {
    li {
      margin: 0 0 6px;
    }
  }

  ul:not(.slick-dots) {
    list-style: none;
    padding: 0 0 0 14px;

    li:before {
      content: "\2022";
      color: $color-main;
      font-size: 19px;
      margin: 0 8px 0 -15px;
    }
  }

  ol {
    counter-reset: item;
    padding: 0;

    ol {
      margin: 15px 0 0;
    }

    & > li > ol > li {
      font-size: 14px;
      line-height: 26px;
      font-weight: 400;
      margin: 0 0 8px;
    }

    li {
      display: block;
      margin: 0 0 11px;

      &:before {
        content: counters(item, ".") ". ";
        counter-increment: item;
        font-weight: 700;
      }
    }
  }

  a:not(.btn):not(.link_decor) {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  p {
    margin: 20px 0;

    @include respond-to(tablet) {
      margin: 30px 0;
    }
  }

  blockquote {
    font-size: 16px;
    line-height: 20px;
    font-weight: 700;
    font-style: italic;

    p:last-child {
      margin: 0;
    }
  }

  figure {
    img {
      width: 100%;
      border-radius: 10px;
      margin: 0 0 10px;
    }
  }

  figcaption {
    text-align: center;
    line-height: 18px;
    font-style: italic;

    html body & a {
      color: $color-main;
    }
  }

  .columns {
    p {
      page-break-inside: avoid;
      break-inside: avoid;
      -webkit-column-break-inside: avoid;
      margin-top: 0;
    }

    @include respond-to(macbook) {
      columns: 2;
      column-gap: 35px;
    }
  }

  :first-child {
    margin-top: 0;
  }

  :last-child:not(.container) {
    margin-bottom: 0;
  }
}
