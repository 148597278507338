.not-found {
  background: url("../../../public/images/error-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 2;

  text-align: center;
  color: $color-black;

  padding: 60px 20px;

  position: relative;

  margin: 0 -20px;

  @include respond-to(tablet) {
    margin: 0 -30px;
  }

  @include respond-to(maxLayoutWidth) {
    margin: 0;
  }

  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba($color-white, 0.85);
  }

  &__inner {
    max-width: 570px;
    position: relative;
    z-index: 2;
  }

  &__error {
    font-weight: 700;

    font-size: 100px;
    line-height: 188px;
    margin: 0 0 -29px;

    @include respond-to(tablet) {
      font-size: 200px;
      line-height: 249px;
      margin: 0 0 6px;
    }
  }

  &__title {
    color: $color-black;
    margin: 0 0 16px;

    @include respond-to(tablet) {
      margin: 0 0 20px;
    }
  }

  &__text {
    font-size: 14px;
    line-height: 26px;

    a {
      color: $color-main;
    }

    @include respond-to(tablet) {
      font-size: 16px;
      line-height: 20px;
    }
  }

  &__btn {
    margin: 30px 0 0;

    @include respond-to(tablet) {
      margin: 40px 0 0;
    }
  }
}
