.footer {
  background: $color-white;
  border-top: 1px solid $color-border;
  padding: 40px 20px 0;

  @include respond-to(tablet) {
    padding: 50px 30px 0;
  }

  @include respond-to(macbook) {
    padding: 60px 30px 0;
  }

  @include respond-to(desktop) {
    padding: 60px 0 0;
  }

  &__wrapper {
    @include respond-to(macbook) {
      max-width: 1200px;
      width: 100%;
      margin: 0 auto;
      padding: 0 0 80px;

      display: grid;
      grid-template-columns: auto 1fr;
      grid-column-gap: 178px;
    }
  }

  &__top {
    min-height: 80px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__consult {
    margin: 0 0 30px;

    @include respond-to(macbook) {
      margin: 0;
    }

    &-label {
      font-size: 16px;
      line-height: 20px;
      color: $color-black;

      margin: 0 0 15px;

      @include respond-to(tablet) {
        margin: 0 0 10px;

        font-size: 20px;
        line-height: 27px;
      }

      @include respond-to(macbook) {
        margin: 0 0 15px;
      }
    }

    &-number {
      font-size: 27px;
      line-height: 35px;
      font-weight: 700;
      color: $color-black;
      text-decoration: none;
      transition: $global-transition;

      &:hover {
        color: $color-main-hover;
      }

      @include respond-to(tablet) {
        font-size: 30px;
        line-height: 39px;
      }
    }
  }

  &__socials {
    &-inner {
      text-align: center;

      display: grid;
      grid-template-columns: repeat(auto-fill, 40px);
      grid-column-gap: 20px;
      grid-row-gap: 20px;

      margin: 0 0 40px;

      @include respond-to(tablet) {
        grid-column-gap: 30px;
        grid-row-gap: 15px;

        margin: 0 0 50px;
      }

      @include respond-to(macbook) {
        margin: 0;
      }
    }

    &-title {
      grid-column: 1 / -1;

      text-align: left;

      font-size: 16px;
      line-height: 20px;
      color: $color-black;

      @include respond-to(tablet) {
        font-size: 20px;
        line-height: 27px;
      }
    }

    &-item {
      width: 40px;

      svg {
        width: 100%;
        height: 100%;
      }
    }
  }

  &__copyright {
    background: $color-main;
    color: $color-white;
    padding: 15px 20px;
    margin: 0 -20px;
    font-size: 14px;
    line-height: 18px;
    text-align: center;

    @include respond-to(tablet) {
      margin: 0 -30px;
    }

    @include respond-to(desktop) {
      margin: 0;
    }

    p {
      margin: 0;
    }

    a {
      color: $color-white;
      text-decoration: underline;

      &:hover, &:active {
        color: $color-white;
        text-decoration: none;
      }
    }
  }
}
