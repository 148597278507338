.page {
  &__header {
    margin: 0 -1 * $padding;
    border-bottom: 1px solid $color-border;

    display: flex;
    flex-direction: column;
    justify-content: center;

    position: relative;
    padding: 100px 0 40px;

    color: $color-black;

    overflow: hidden;

    @include respond-to(tablet) {
      margin: 0 -1 * $tPadding;
      padding: 120px 0 50px;
    }

    @include respond-to(macbook) {
      padding: 145px 0 60px;
    }

    @include respond-to(maxLayoutWidth) {
      margin: 0;
    }

    &_block {
      min-height: 0;
      margin-top: 20px;
      padding: 40px $padding;
      border-top: 1px solid $color-border;
      border-bottom: none;

      @include respond-to(tablet) {
        margin-top: 25px;
        padding: 50px $tPadding;
      }

      @include respond-to(desktop) {
        margin-top: 30px;
        padding: 60px 0;
      }
    }

    &:after {
      content: "";
      position: absolute;
      z-index: 2;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background: rgba($color-white, 0.85);
    }

    &-cover {
      position: absolute;
      z-index: 1;

      object-fit: cover;

      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }

    &-body {
      position: relative;
      z-index: 3;
      padding: 0 $padding;
      color: $color-black;

      &_center {
        text-align: center;

        .page__header-title {
          margin: 0 auto;
        }
      }

      @include respond-to(tablet) {
        padding: 0 $tPadding;
      }

      @include respond-to(desktop) {
        width: $layoutWidth;
        margin: 0 auto;
        padding: 0;
      }
    }

    &-title {
      margin: 0;

      &_center {
        text-align: center;
        margin: 0 auto;
      }

      @include respond-to(macbook) {
        max-width: 892px;
      }

      .page__header_plan & {
        @include respond-to(desktop) {
          text-align: center;
        }
      }
    }

    &-subtitle {
      font-size: 16px;
      line-height: 20px;
      font-weight: 700;
      margin: 10px 0 0;

      @include respond-to(tablet) {
        font-size: 20px;
        line-height: 27px;
        margin: 20px 0 0;
      }

      @include respond-to(macbook) {
        margin: 30px 0 0;
      }
    }

    &-intro {
      font-weight: 500;
      line-height: 1.45;
      margin: 27px 0 0;

      p {
        margin-bottom: 0;
      }

      @include respond-to(tablet) {
        font-size: 18px;
        line-height: 1.67;
      }

      @include respond-to(macbook) {
        width: 892px;

        .page__header-body_center & {
          margin: 27px auto 0;
        }
      }
    }

    &-btn {
      margin: 20px 0 0;

      @include respond-to(tablet) {
        margin: 30px 0 0;
      }
    }

    &-buttons {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      margin: 20px 0 0;

      @include respond-to(tablet) {
        margin: 30px 0 0;
        flex-direction: row;
        justify-content: center;
      }

      .btn {
        margin: 10px 0;

        @include respond-to(tablet) {
          margin: 0 10px;
        }

        &:first-child {
          margin-top: 0;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &-payments {
      @include respond-to(tablet) {
        min-width: 318px;
      }
    }
  }

  &__block-body_wide {
  }

  &__back-link {
    @include respond-to(tablet) {
      margin-left: auto;
      margin-right: auto;
    }
  }

  .ant-spin {
    display: block;

    @include respond-to(tablet) {
      grid-column: 1 / -1;
    }
  }

  &-list__item .list__item-title {
    transition: $global-transition;

    &:hover {
      color: $color-main;
    }
  }
}

.container {
  margin: 0 auto 40px;

  @include respond-to(tablet) {
    margin: 0 auto 50px;
  }

  @include respond-to(maxLayoutWidth) {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto 60px;
  }

  &:first-child {
    margin-top: 40px;

    @include respond-to(tablet) {
      margin-top: 50px;
    }

    @include respond-to(maxLayoutWidth) {
      margin-top: 60px;
    }
  }

  &-wide {
    @include respond-to(maxLayoutWidth) {
      max-width: 100%;
      padding: 0 30px;
    }
  }
}
