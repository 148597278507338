.contacts {
  &__map {
    height: 350px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 -1 * $padding;
    background: $color-grey;

    .leaflet-container {
      width: 100%;
      height: 100%;
    }

    .leaflet-pane {
      z-index: 95;
    }

    .leaflet-top,
    .leaflet-bottom {
      z-index: 98;
    }

    @include respond-to(tablet) {
      margin: 0 -1 * $tPadding;
      height: 400px;
    }

    @include respond-to(desktop) {
      margin: 0;
    }

    .ol-control button {
      background-color: rgba($color-main, 0.5);
      transition: $global-transition;

      &:hover {
        background-color: $color-main;
      }
    }
  }
}
