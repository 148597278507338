$color-black: #000000;
$color-white: #FFFFFF;
$color-white-hover: #DDC6C5;
$color-main: #781F19;
$color-main-hover: #853C36;
$color-main-disabled: #DDC6C5;
$color-main-active: #5E100A;
$color-placeholder: #999999;
$color-border: #E0E0E0;

$color-green: #43A047;
$color-yellow: #F9A825;
$color-red: #D52B1E;
$color-blue: #448aff;
$color-grey: #F5F5F5;

$global-font-size: 14px;
$global-line-height: 26px;

$global-transition: all 0.2s ease-out;
$transition-time: 0.25s;

$buttonWidth: 182px;
$headerHeight: 80px;
$padding: 20px;
$tPadding: 30px;

$small: 374px;
$crop: 500px;
$tablet: 768px;
$macbook: 1075px;
$downMacbook: 1074.98px;
$desktop: 1200px;
$layoutWidth: 1200px;
$maxLayoutWidth: 1220px;
$statTableWidth: 816px;
