.slider-certificates {
  position: relative;
  margin: 30px 0 0;

  @include respond-to(tablet) {
    margin: 40px 0 0;
  }

  &__img-wrap {
    position: relative;
    display: block;
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    will-change: transform;
    margin: 0 auto;
    height: 230px;

    @include respond-to(tablet) {
      height: auto;
      max-height: 500px;
    }
  }

  .ant-image {
    display: block;
    height: 230px;

    @include respond-to(tablet) {
      height: 500px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 10px;
      transform: translateZ(0);
      margin: 0;
    }
  }
}
