@mixin respond-to($media) {
  @if $media == small {
    @media only screen and (max-width: $small) {
      @content;
    }
  }
  @if $media == crop {
    @media only screen and (min-width: $crop) {
      @content;
    }
  }
  @if $media == small_landscape {
    @media only screen and (min-width: $small) and (orientation: landscape) {
      @content;
    }
  }
  @if $media == tablet {
    @media only screen and (min-width: $tablet) {
      @content;
    }
  }
  @if $media == statTable {
    @media only screen and (min-width: $statTableWidth) {
      @content;
    }
  }
  @if $media == downMacbook {
    @media only screen and (max-width: $downMacbook) {
      @content;
    }
  }
  @if $media == macbook {
    @media only screen and (min-width: $macbook) {
      @content;
    }
  }
  @if $media == desktop {
    @media only screen and (min-width: $desktop) {
      @content;
    }
  }
  @if $media == maxLayoutWidth {
    @media only screen and (min-width: $maxLayoutWidth) {
      @content;
    }
  }
}
