@media only screen and (min-width: 575px) {
  .list-cards {
    grid-template-columns: repeat(2, 1fr);
  }
}

.list-cards {
  grid-gap: 20px;
  display: grid;
  margin: 15px 0 30px;

  @include respond-to(tablet) {
    grid-gap: 30px;
    margin: 25px 0 40px;
  }

  @include respond-to(macbook) {
    grid-template-columns: repeat(3, 1fr);
  }

  &__item {
    font-size: 16px;
    line-height: 1.25;
    font-weight: 700;
    text-align: center;
    align-items: center;
    border-radius: 10px;
    display: flex;
    min-height: 150px;
    justify-content: center;
    border: 2px solid $color-main;
    padding: 20px;
    overflow: hidden;

    @include respond-to(tablet) {
      font-size: 20px;
      line-height: 1.35;
      padding: 30px;
    }
  }
}
