.ant-carousel {
  .page__block_cover & {
    color: $color-white;
  }

  .slick-dots {
    position: relative;
    bottom: 0;
    padding: 0;
    margin: 10px auto 0;
  }

  .slick-dots.slider__dots_many {
    width: 100px;
    overflow: hidden;
    display: block !important;

    ul {
      width: 100%;

      li {
        button {
          transform: scale(0.4);
        }

        &[data-type="small"] {
          button {
            transform: scale(0.7);
          }
        }
      }
    }
  }

  .slick-dots ul {
    position: relative;
    white-space: nowrap;
    padding: 0;
    margin: 0;
    transition: $global-transition;

    li {
      width: 10px;
      height: 10px;
      min-height: auto;
      display: inline-block;
      margin: 10px 5px 0;

      @include respond-to(tablet) {
        margin: 15px 5px 0;
      }

      button {
        width: 100%;
        height: 100%;
        background-color: $color-white;
        border-radius: 50%;
        vertical-align: middle;
        position: relative;
        transition: opacity 0.2s ease-out;
        cursor: pointer;
        opacity: 0.35;
      }

      &.slick-active,
      &:hover {
        width: 10px;
        opacity: 1;
      }

      &.slick-active,
      &[data-type="std"] {
        button {
          transform: scale(1);
        }
      }

      &:only-child {
        display: none;
      }
    }
  }

  .slider__dots_accent .slick-dots li button {
    background-color: $color-main;
  }
}

.ant-carousel .slick-arrow,
.slider-arrow {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 50%;
  font-size: 0;
  background-color: $color-main;
  border-radius: 6px;
  transition: $global-transition;
  transform: translateY(-50%) rotate(-90deg);
  padding: 0;
  outline: none;
  cursor: pointer;
  border: none;
  margin: -35px 0 0;
  z-index: 2;
  display: none !important;

  &:hover {
    background-color: $color-main-hover;
  }

  &:focus {
    background-color: $color-main-active;
  }

  &:not(.slick-hidden) {
    @media only screen and (min-width: 1420px) {
      display: block !important;
    }
  }

  svg {
    fill: $color-white;
  }

  &.slick-disabled {
    opacity: 0.25;
  }
}

.ant-carousel .slick-prev,
.slider-prev {
  left: -90px;
  transform: translateY(-50%);
}

.ant-carousel .slick-next,
.slider-next {
  right: -90px;
  transform: translateY(-50%) rotate(-180deg);
}

.slider__item:focus {
  outline: none;
}
