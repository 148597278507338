.videos {
  a {
    text-decoration: none;
  }

  &__slider {
    position: relative;
    margin: 0 -10px;

    @include respond-to(tablet) {
      margin: 0 -1 * $tPadding;
    }

    @media only screen and (min-width: 920px) {
      margin: 0 -15px;
    }
  }

  &__item {
    padding: 0 10px;
    margin: 0 0 10px;

    @include respond-to(tablet) {
      padding: 0 15px;
    }
  }

  &__img-wrap {
    display: block;
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    will-change: transform;
    height: 230px;
    margin: 0 0 10px;

    @include respond-to(tablet) {
      margin: 0 0 20px;
    }

    @include respond-to(macbook) {
      height: 210px;
    }

    &:hover {
      .videos__img {
        transform: translateZ(0) scale(1.02);
      }

      .videos__img-icon svg {
        fill: $color-white;
      }
    }

    &::before {
      content: "";
      position: absolute;
      z-index: 1;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      border-radius: 10px;
      background: rgba($color-black, 0.25);
      overflow: hidden;
    }
  }

  &__img {
    width: 100%;
    height: 100%;

    object-fit: cover;
    transition: all $transition-time ease-out;
    border-radius: 10px;
    transform: translateZ(0);
    margin: 0;
  }

  &__img-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 1;

    svg {
      width: 56px;
      height: auto;
      fill: rgba($color-white, 0.85);
      transition: $global-transition;

      @include respond-to(tablet) {
        width: 118px;
      }

      @include respond-to(macbook) {
        width: 92px;
      }
    }
  }

  &__title {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: none;
    margin: 0;

    @include respond-to(tablet) {
      -webkit-line-clamp: 1;
    }

    @include respond-to(macbook) {
      -webkit-line-clamp: 2;
    }

    a,
    & {
      transition: $global-transition;
    }

    &:hover,
    a:hover {
      color: $color-main-hover;
    }
  }
}
