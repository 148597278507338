table {
  background-color: transparent;

  ul {
    padding: 0 !important;

    li::before {
      display: none;
    }
  }
}

caption {
  padding-top: 8px;
  padding-bottom: 8px;
  color: #777;
  text-align: left;
}

th {
  text-align: left;
}

td.collapsable {
  max-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  ul {
    white-space: normal;

    li:not(li:first-child) {
      display: none;
    }
  }
}

.collapsable__btn {
  color: $color-main;

  span {
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }

    &:first-child {
      display: none;
    }

    td.collapsable & {
      &:last-child {
        display: none;
      }

      &:first-child {
        display: inline;
      }
    }
  }
}

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;

  > thead,
  > tbody,
  > tfoot {
    > tr {
      > th,
      > td {
        padding: 8px;
        line-height: 1.428571429;
        vertical-align: top;
        border-top: 1px solid #ddd;
      }
    }
  }

  > thead > tr > th {
    vertical-align: middle;
    border-bottom: 2px solid #ddd;
  }

  > caption + thead,
  > colgroup + thead,
  > thead:first-child {
    > tr:first-child {
      > th,
      > td {
        border-top: 0;
      }
    }
  }

  > tbody + tbody {
    border-top: 2px solid #ddd;
  }

  .table {
    background-color: $color-white;
  }
}

.table-condensed {
  > thead,
  > tbody,
  > tfoot {
    > tr {
      > th,
      > td {
        padding: 5px;
      }
    }
  }
}

.table-bordered {
  border: 1px solid #ddd;
  > thead,
  > tbody,
  > tfoot {
    > tr {
      > th,
      > td {
        border: 1px solid #ddd;
      }
    }
  }
  > thead > tr {
    > th,
    > td {
      border-bottom-width: 2px;
    }
  }
}

.table-striped {
  > tbody > tr:nth-of-type(odd) {
    background-color: #f9f9f9;
  }
}

.table-hover {
  > tbody > tr:hover {
    background-color: #f5f5f5;
  }
}

table col[class*="col-"] {
  position: static;
  float: none;
  display: table-column;
}
table {
  td,
  th {
    &[class*="col-"] {
      position: static;
      float: none;
      display: table-cell;
    }
  }
}

.table-responsive {
  overflow-x: auto;
  min-height: 0.01%;

  @media screen and (max-width: $tablet) {
    width: 100%;
    margin-bottom: (20px * 0.75);
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    border: 1px solid #ddd;

    > .table {
      margin-bottom: 0;

      > thead,
      > tbody,
      > tfoot {
        > tr {
          > th,
          > td {
            white-space: nowrap;
          }
        }
      }
    }

    > .table-bordered {
      border: 0;

      > thead,
      > tbody,
      > tfoot {
        > tr {
          > th:first-child,
          > td:first-child {
            border-left: 0;
          }
          > th:last-child,
          > td:last-child {
            border-right: 0;
          }
        }
      }

      > tbody,
      > tfoot {
        > tr:last-child {
          > th,
          > td {
            border-bottom: 0;
          }
        }
      }
    }
  }
}

.scrollable {
  position: relative;

  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 5%;

    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 5%, rgba(0, 0, 0, 0.2) 100%);
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: right;

    opacity: 0;
    transition: $global-transition;
    z-index: 1;
  }

  td {
    position: relative;
    z-index: 2;
  }

  &__inner {
    position: relative;
    overflow-x: auto;
  }

  &.scrollable-mobile {
    &:before {
      opacity: 1;
    }
  }
}
