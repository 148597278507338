.chat {
  position: static;

  &__buttons {
    position: fixed;
    right: 20px;
    bottom: 20px;
    font-size: 16px;
    line-height: 20px;
    z-index: 101;
    width: 56px;
    height: 56px;

    .nav-open & {
      z-index: 9;
    }

    @include respond-to(tablet) {
      right: 30px;
      bottom: 30px;
    }

    @include respond-to(desktop) {
      right: 60px;
      bottom: 60px;
    }
  }

  &__btn {
    min-width: 56px;
    width: 56px;
    height: 56px;
    border-radius: 100%;
    color: $color-main;
    border: 2px solid $color-white;
    opacity: 1;
    margin: 0;

    position: absolute;
    left: 0;
    top: 0;
    z-index: 4;

    &:hover,
    &:focus {
      border-color: $color-white;
    }

    &:nth-child(2) {
      top: -74px;
      z-index: 3;
    }

    &:nth-child(3) {
      top: -148px;
      z-index: 2;
    }

    &:nth-child(4) {
      top: -221px;
      z-index: 1;
    }

    .anticon {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 1;

      svg {
        width: 32px;
        height: 32px;
      }

      & + span {
        margin: 0;
      }
    }

    &_unread {
      animation: shining 1.5s infinite;
    }

    &_hidden {
      transform: scale(0);
      transform-origin: 50% 50%;
      left: 0;

      &:nth-child(2) {
        top: -34px;
      }

      &:nth-child(3) {
        top: -108px;
      }

      &:nth-child(4) {
        top: -181px;
      }
    }

    &_chat {
      .anticon {
        svg {
          width: 28px;
        }
      }
    }

    &-unread {
      position: absolute;
      width: 20px;
      height: 20px;
      border-radius: 100%;
      border: 2px solid $color-white;
      background: $color-main;
      left: -2px;
      top: -3px;
      z-index: 2;

      font-size: 10px;
      line-height: 16px;
      font-weight: 700;
      color: $color-white;

      display: flex;
      align-items: center;
      justify-content: center;

      transition: none;
    }

    &-hint {
      position: absolute;
      height: 38px;
      border: 2px solid $color-main;
      background: $color-white;
      padding: 0 10px;
      border-radius: 10px;
      right: 100%;
      top: 50%;
      transform: translate(-20px, -50%);

      white-space: nowrap;
      display: flex;
      align-items: center;

      color: $color-black;
      font-size: 14px;
      font-weight: 700;

      @include respond-to(macbook) {
        opacity: 0;
        transition: opacity 0.25s ease;
      }

      .chat__buttons_single & {
        display: none;
      }
    }

    &:hover {
      .chat__btn-hint {
        @include respond-to(macbook) {
          opacity: 1;
        }
      }
    }
  }

  &__backdrop {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    background: rgba($color-black, 0.8);

    @include respond-to(macbook) {
      display: none;
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    background-color: $color-white;

    z-index: 110;

    position: fixed;
    left: 100%;
    top: 0;
    bottom: 0;
    width: 100%;
    transition: left 0.25s ease;

    @include respond-to(tablet) {
      width: 375px;
      height: 667px;
      position: fixed;
      right: 0;
      top: auto;
      left: auto;
      box-shadow: -3px 0 6px 0 rgba($color-black, 0.16);
      transform: translateY(105%);
      transition: transform $transition-time ease;
    }

    .chat-open & {
      left: 0;

      @include respond-to(tablet) {
        left: auto;
        transform: translateY(0);
      }
    }
  }

  &__header-top {
    display: flex;
    align-items: center;
    color: $color-white;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    background: $color-main;
    min-height: 50px;
    padding: 4px 7px 4px 7px;
  }

  &__title {
    padding: 0 0 0 13px;
  }

  &__header-icons {
    display: flex;
    margin: 0 0 0 auto;
  }

  &__header-icon {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;

    span {
      display: flex;
    }

    svg {
      width: 40px;
      height: 40px;
      fill: $color-white;
    }
  }

  &__header-icon-search {
    svg {
      width: 17px;
      height: 17px;
    }
  }

  &__header-icon-arrow {
    transform: rotate(-90deg);
  }

  &__search {
    position: relative;
    width: 100%;

    .ant-form-item {
      margin: 0;
    }

    &-field {
      &.ant-input-search .ant-input-affix-wrapper {
        background-color: $color-white;
        border-color: $color-white;
      }

      &.ant-input-search > .ant-input-group > .ant-input-group-addon .ant-input-search-button span,
      &.ant-input-search > .ant-input-group > .ant-input-group-addon .ant-input-search-button svg {
        width: 17px;
        height: 17px;
      }

      &.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child {
        left: 0;
      }

      .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
      }

      &.ant-input-search > .ant-input-group > .ant-input-group-addon .ant-input-search-button:hover {
        border-color: $color-main;
        background: inherit;
      }
    }

    .ant-form-item-has-error .ant-form-item-explain {
      display: none;
    }

    .ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
    .ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
      border-color: $color-white;
    }

    &-result {
      display: flex;
      align-items: center;
      height: 100%;

      &-icon {
        display: flex;
        transform: rotate(-90deg);
        cursor: pointer;

        &:first-child {
          transform: rotate(90deg);
        }

        &_disabled {
          pointer-events: none;
          cursor: default;
          opacity: 0.5;
        }
      }

      &-icons {
        display: flex;
        gap: 5px;
        margin: 0 -11px 0 auto;

        svg {
          width: 40px;
          height: 40px;
          fill: $color-main;
        }
      }
    }
  }

  &__consultant {
    display: grid;
    align-items: center;
    padding: 20px 20px 19px;
    border-bottom: 1px solid $color-border;

    grid-template-columns: 70px auto;
    grid-column-gap: 15px;

    &-avatar {
      grid-row: 1 / 3;

      .ant-badge-dot {
        width: 10px;
        height: 10px;
        top: 10px;
        right: 10px;
        box-shadow: 0 0 0 2px $color-white;
      }

      .anticon {
        width: 70px;
        height: 70px;

        svg {
          width: 70px;
          height: 70px;
          fill: $color-white;
        }
      }
    }

    &-name {
      font-size: 16px;
      line-height: 20px;
      text-transform: uppercase;
      font-weight: 700;

      align-self: end;
      margin: 0 0 5px;
    }

    &-position {
      font-size: 14px;
      line-height: 18px;
    }
  }

  &__content {
    margin: auto 0 0;
    display: flex;
    flex-direction: column;
    width: 100%;

    &-wrap {
      position: relative;
      flex-grow: 2;
      overflow: hidden;
    }
  }

  .rcs-inner-container {
    padding: 20px 20px 0;
  }

  &__message {
    display: inline-block;
    text-align: left;
    color: $color-black;
    background-color: $color-border;
    border-radius: 5px 20px 20px 5px;
    max-width: 83%;
    padding: 11px 15px;
    margin: 0 0 5px;

    &:first-child {
      border-radius: 20px 20px 20px 5px;

      &.chat__message_own {
        border-radius: 20px 20px 5px 20px;
      }
    }

    &_group {
      margin: 0 0 15px;

      & + .chat__message {
        border-radius: 20px 20px 20px 5px;
      }

      & + .chat__message_own {
        border-radius: 20px 20px 5px 20px;
      }
    }

    &_own {
      background-color: $color-main;
      color: $color-white;
      border-radius: 20px 5px 5px 20px;
      align-self: flex-end;

      .chat__message_group + & {
        border-radius: 20px 20px 5px 20px;
      }
    }

    &_sending {
      opacity: 0.8;
    }

    p:last-of-type {
      margin: 0;
    }

    &_uploading {
      padding: 5px 0;
      display: flex;
      justify-content: center;
      background: transparent;
      max-width: 100%;
    }

    &_found {
      outline: 3px solid $color-main-disabled;

      em {
        display: inline;
        padding: 0em 0 0.06em;
        background: $color-main;
        color: $color-white;
        box-shadow: 0.15em 0 0 $color-main, -0.15em 0 0 $color-main;
        font-style: normal;
      }

      &.chat__message_own em {
        background: $color-white;
        color: $color-main;
        box-shadow: 0.15em 0 0 $color-white, -0.15em 0 0 $color-white;
      }
    }
  }

  &-message {
    &__body {
      font-size: 16px;
      line-height: 20px;
    }

    &__attachment {
      font-weight: 700;
      text-decoration: underline;
      cursor: pointer;

      transition: color 0.25s ease;

      display: block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      &:hover {
        text-decoration: none;

        overflow: visible;
        white-space: normal;
        word-break: break-all;
      }

      &:active {
        color: $color-main;
      }
    }

    &__name {
      color: $color-main;
      font-weight: 500;
      line-height: 16px;
      margin: 0 0 5px;
    }

    &__date {
      font-size: 10px;
      line-height: 13px;
      font-style: italic;
      margin: 5px 0 0;
    }
  }

  &__link {
    display: flex;
    flex-direction: column;
    font-weight: 700;
    text-decoration: none;
    margin: 5px 0;

    span {
      color: $color-black;
      text-decoration: underline;
      margin: 0 0 5px;

      display: block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      .chat__message_own & {
        color: $color-white;
      }

      .chat__message:hover & {
        word-break: break-all;
        overflow: visible;
        white-space: normal;
      }
    }

    b {
      color: $color-main;

      .chat__message_own & {
        color: $color-white;
      }
    }

    &:hover {
      span {
        text-decoration: none;
      }
    }
  }

  &__footer {
    border-top: 1px solid $color-border;
    min-height: 64px;
    padding: 10px 20px;
    flex-shrink: 0;
  }

  &__form {
    &.ant-form {
      display: flex;
      flex-direction: row;
      margin: 0;
      position: relative;
    }

    &_small {
      align-items: center;
    }

    .ant-form-explain {
      display: none;
    }

    .form__item.form__item {
      flex-grow: 2;
      margin: 0 10px 0 0;
    }

    .ant-input {
      height: 38px;
      font-weight: 400;
      border-radius: 10px;
      border: 1px solid $color-border;
      padding: 8px 16px;
      resize: none;
      caret-color: $color-main;

      &:hover,
      &:focus {
        border-color: $color-border;
        border-right-width: 1px !important;
      }
    }

    .has-error {
      .ant-input,
      .ant-input:not([disabled]) {
        border-color: $color-border;

        &:hover {
          border-color: $color-border;
        }
      }
    }

    .ant-input-affix-wrapper {
      &:hover .ant-input:not(.ant-input-disabled) {
        border-color: $color-border;
        border-right-width: 1px !important;
      }

      .ant-input:not(:last-child) {
        padding-right: 45px;
      }
    }

    .ant-input-suffix {
      right: 5px;
    }

    .anticon {
      width: 36px;
    }

    .btn {
      margin-top: 2px;
      margin-bottom: 1px;

      &:last-child {
        margin-top: 2px;
      }
    }
  }

  &__attachment {
    height: 43px;
    position: absolute;
    right: 50px;

    &_space-right {
      right: 100px;
    }

    .ant-upload {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 43px;
      cursor: pointer;

      .anticon {
        width: 43px;
        height: 43px;

        svg {
          fill: $color-main;
          transition: $global-transition;
          height: 43px;
          width: 43px;
        }
      }

      .anticon,
      .anticon svg {
        height: 43px;
        width: 43px;
      }

      &.ant-upload-disabled {
        .anticon {
          svg {
            opacity: 0.6;
          }
        }
      }

      &:hover .anticon svg {
        fill: $color-main;
      }
    }
  }

  .ant-badge-status-success {
    background: $color-green;
  }

  .ant-badge-status-error {
    background: $color-red;
  }

  &__spin-wrap {
    display: flex;
    align-items: flex-start;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 70px;
    justify-content: center;
    background: rgb(255, 255, 255);
    background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
    padding: 20px 0;

    &_bottom {
      align-items: end;
      top: auto;
      bottom: 0;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, white 100%);
    }

    &_center {
      align-items: center;
      top: 50%;
      transform: translateY(-50%);
      background: transparent;
    }
  }

  &__stickers-popover {
    .rcs-inner-container > div {
      margin-right: 0px !important;
    }

    .rcs-custom-scroll .rcs-custom-scrollbar {
      right: 0px;
    }
  }

  &__stickers-wrap {
    display: flex;
    flex-wrap: wrap;
    width: 285px;
    max-height: 240px;
  }

  &__sticker {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70px;
    height: 70px;

    border-radius: 10px;

    padding: 5px;
    transition: $global-transition;
    cursor: pointer;

    &:hover {
      background-color: $color-grey;
    }

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  &__stickers-btn {
    margin: 0 10px 0 0;

    .anticon {
      width: 23px;
    }
  }

  &-message__sticker {
    width: 200px;
    height: 200px;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
}

@keyframes shining {
  0% {
    box-shadow: 0 0 0 0 rgba($color-main, 0.7);
  }
  70% {
    box-shadow: 0 0 0 10px rgba($color-main, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba($color-main, 0);
  }
}
