.life {
  &__slider {
    position: relative;
    margin: 0 -10px;

    @include respond-to(tablet) {
      margin: 0 -1 * $tPadding;
    }

    @media only screen and (min-width: 920px) {
      margin: 0 -15px;
    }

    @include respond-to(macbook) {
      margin: 0;
    }
  }

  &__item {
    padding: 0 10px;
    margin: 0 0 10px;

    @include respond-to(tablet) {
      padding: 0 15px;
    }
  }

  &__img-wrap {
    position: relative;
    display: block;
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    will-change: transform;
    margin: 0 auto;
    height: 230px;

    @include respond-to(tablet) {
      height: auto;
      max-height: 500px;
    }

    @include respond-to(macbook) {
      max-width: 900px;
    }

    .ant-image {
      display: block;
      height: 230px;

      @include respond-to(tablet) {
        height: auto;
        max-height: 500px;
      }

      @include respond-to(macbook) {
        max-width: 900px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 10px;
        transform: translateZ(0);
        margin: 0;
      }
    }
  }
}

.ant-image {
  position: initial;

  &-mask-info {
    font-size: 0;

    span {
      font-size: 25px;
    }
  }
}
