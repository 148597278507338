html body {
  min-height: 100vh;
}

body {
  font-size: $global-font-size;
  line-height: $global-line-height;

  color: $color-black;
  background: $color-white;

  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;

  overflow-y: scroll;

  min-width: 320px;

  &.nav-open,
  &.languages-open,
  &.modal-open {
    height: 100vh;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;

    position: relative;
    width: 100vw;
  }
}

div,
input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: rgba($color-white, 0.5);
}

body a {
  color: $color-black;

  &:hover {
    color: $color-main;
  }
}

input,
select,
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
}

button {
  outline: none;
  cursor: pointer;
}

.app__wrapper {
  display: flex;
  flex-direction: column;

  min-height: 100vh;
}

.content {
  flex-grow: 2;

  display: flex;
  flex-direction: column;

  padding: 0 $padding;
  background: $color-white;

  @include respond-to(tablet) {
    padding: 0 $tPadding;
  }

  @include respond-to(maxLayoutWidth) {
    padding: 0;
  }
}

.t-h2 {
  font-size: 20px;
  line-height: 27px;
  text-transform: uppercase;
  font-weight: 700;
  margin: 0;
}

.t-h3 {
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 20px;

  @include respond-to(tablet) {
    font-size: 20px;
    line-height: 27px;
  }
}

.text-center {
  text-align: center;
  justify-content: center;
}

.border_bottom {
  display: block;
  border-bottom: 1px solid $color-border;

  .list_no-border-last &:last-child {
    border-bottom: none;
  }
}

.btn__more {
  margin: 30px 0 0;

  @include respond-to(tablet) {
    margin: 40px 0 0;
  }
}
