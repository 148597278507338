.main {
  &__intro {
    border: 0;

    &-title {
      @include respond-to(macbook) {
        font-size: 50px;
        line-height: 1.2;
        max-width: none;
      }
    }
  }
}
