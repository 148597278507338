.header {
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 10px;
  align-items: center;
  justify-content: space-between;
  height: 80px;

  position: absolute;
  top: 0;
  right: 0;
  left: 0;

  background: transparent;
  padding: 0 20px;

  @include respond-to(tablet) {
    height: 100px;
    padding: 0 30px;
  }

  @include respond-to(macbook) {
    height: 120px;
  }

  @include respond-to(desktop) {
    padding: 0 40px;
  }

  &-section {
    position: relative;
  }

  &__logo {
    z-index: 10;

    &-icon {
      display: none;

      svg {
        vertical-align: middle;
        fill: $color-main;
        width: 40px;
        height: 40px;
      }

      @include respond-to(macbook) {
        display: block;

        svg {
          width: 358px;
          height: 54px;
        }
      }

      &_mobile {
        display: block;

        @include respond-to(macbook) {
          display: none;
        }
      }
    }
  }

  &__buttons {
    display: flex;
  }

  &__btn {
    text-decoration: none;
    transition: $global-transition;
    margin: 0 0 0 20px;
    z-index: 10;

    @include respond-to(macbook) {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 0 0 40px;
    }

    &-icon {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 40px;
      height: 40px;

      background-color: $color-main;
      border-radius: 50%;
      -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
      transition: $global-transition;

      &:hover {
        background-color: $color-main-hover;
      }

      svg {
        fill: $color-white;
        width: 20px;
        height: 20px;
      }
    }

    &-label {
      display: none;

      @include respond-to(macbook) {
        display: inline-block;
        font-size: 18px;
        line-height: 1.28;
        font-weight: 500;
        margin: 0 0 0 15px;
      }
    }
  }
}
