.accessibility {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  min-height: 50px;
  max-width: $desktop;
  font-size: 18px;
  line-height: 1.67;
  margin: 0 auto;
  padding: 10px 0;

  @include respond-to(tablet) {
    padding: 0 30px;
  }

  @include respond-to(maxLayoutWidth) {
    padding: 0;
  }

  @include respond-to(macbook) {
    flex-direction: row;
    justify-content: space-between;
  }

  &-col {
    display: flex;
    align-items: baseline;
  }

  &__btn-icon {
    line-height: 0;
    margin: 0 10px 0 0;

    svg {
      fill: $color-black;
      width: 20px;
      height: 20px;
    }
  }

  &__btn {
    border-radius: 3px;
    text-decoration: none;
    display: inline-block;
    padding: 0 5px;
    line-height: 20px;
    margin: 0 8px;

    cursor: pointer;

    @include respond-to(tablet) {
      margin: 0 2px;
    }

    &-label {
      margin: 0 5px 0 0;
    }

    &-back {
      text-decoration: underline;
      cursor: pointer;

      &:hover {
        text-decoration: none;
      }
    }

    &_size2 {
      font-size: 20px !important;
    }

    &_size3 {
      font-size: 22px !important;
    }

    &.active {
      box-shadow: inset 0 2px 2px 0 #666;
      background: #b3b3b3;
    }

    &_color1 {
      background-color: $color-white;
      border: 1px solid $color-black;
    }

    &_color2 {
      background-color: $color-black;
      color: $color-white;
      border: 1px solid $color-black;

      &.active {
        box-shadow: none;
        background-color: $color-black;
        border: 1px solid $color-white;
      }
    }

    &_color3 {
      background-color: #309;
      color: $color-white;
      border: 1px solid $color-black;

      &.active {
        box-shadow: none;
        background-color: #309;
      }
    }
  }
}

body.accessibility {
  &-open {
    .page__block-img,
    .post-item__image-link,
    .post__image {
      display: none;
    }
  }

  &-size2 {
    font-size: 22px;
    line-height: 28px;

    p,
    .page__block-subtitle,
    .footer__consult-label,
    .footer__socials-title,
    .page__header-intro,
    .header_subtitle,
    .page__header-subtitle,
    .list__item-title,
    .list-ordered__item,
    .wysiwyg,
    .list-cards__item,
    .greeting__post,
    .greeting__name,
    .card__header-title,
    .card__title,
    .card__subtitle,
    .steps__item-label-title,
    .steps__item-label-text,
    .slider-description__name,
    .slider-description__post,
    .slider-description__content,
    .link_decor,
    .collapse__header-text,
    .collapse__content {
      font-size: 22px;
      line-height: 28px;
    }

    .btn {
      font-size: 22px;
    }

    .wysiwyg {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        font-size: 1.05em;
      }
    }
  }

  &-size3 {
    font-size: 32px;
    line-height: 36px;

    p,
    .page__block-subtitle,
    .footer__consult-label,
    .footer__socials-title,
    .page__header-intro,
    .page__header-subtitle,
    .header_subtitle,
    .list__item-title,
    .list-ordered__item,
    .wysiwyg,
    .list-cards__item,
    .greeting__post,
    .greeting__name,
    .card__header-title,
    .card__title,
    .card__subtitle,
    .steps__item-label-title,
    .steps__item-label-text,
    .slider-description__name,
    .slider-description__post,
    .slider-description__content,
    .link_decor,
    .collapse__header-text,
    .collapse__content {
      font-size: 32px;
      line-height: 36px;
    }

    .btn {
      font-size: 32px;
    }

    .wysiwyg {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        font-size: 1.05em;
      }
    }
  }

  &-color1,
  &-color2,
  &-color3 {
    .table-striped > tbody > tr:nth-of-type(odd),
    .list-cards__item,
    .greeting,
    .steps__item:before {
      background-color: transparent;
    }

    .page__block-img,
    .card__header img,
    .steps__item-decor {
      display: none;
    }
  }

  &-color1 {
    a,
    a:hover,
    a:focus,
    .card__body,
    .list-cards__item,
    .btn_primary {
      color: $color-black;
      border-color: $color-black;
    }

    header,
    .list-ordered__item:before,
    .page__block_cover,
    .page__block_cover .title,
    .footer__copyright,
    .page-list__item .list__item-title:hover,
    .list-cards__item,
    .steps__item:before,
    .page__block_cover .ant-carousel {
      color: $color-black;
    }

    .page__header:after,
    .list-ordered__item:before,
    .page__block_cover::before,
    .footer__copyright {
      background-color: $color-white;
    }

    .header__logo-icon,
    .btn_primary {
      svg {
        fill: $color-black;
      }
    }

    .title_decor::before,
    .card__header::after,
    .slider-description__content:before,
    .link_decor::before,
    .collapse__header-icon,
    .ant-carousel .slider__dots_accent .slick-dots li button,
    .ant-carousel .slick-dots ul li button {
      background-color: $color-black;
    }

    .footer,
    .btn_primary {
      background-color: $color-white;
    }

    .collapse__item {
      box-shadow: 0 0 0 2px $color-black;
    }
  }

  &-color2 {
    background-color: $color-black;
    color: $color-white;

    .ant-layout,
    .content {
      background-color: $color-black;
    }

    a,
    a:hover,
    a:focus,
    .card__body,
    .list-cards__item {
      color: $color-white;
      border-color: $color-white;
    }

    header,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .page__header-body,
    .list-ordered__item:before,
    .page__block_cover,
    .page__block_cover .title,
    .footer__copyright,
    .page-list__item .list__item-title:hover,
    .footer__consult-label,
    .footer__socials-title,
    .list-cards__item,
    .steps__item:before,
    .page__block_cover .ant-carousel {
      color: $color-white;
    }

    .page__header:after,
    .list-ordered__item:before,
    .page__block_cover::before,
    .footer__copyright,
    .list__item-title:before {
      background: $color-black;
    }

    .header__logo-icon,
    .btn_primary,
    .accessibility__btn-icon {
      svg {
        fill: $color-white;
      }
    }

    .title_decor::before,
    .card__header::after,
    .slider-description__content:before,
    .link_decor::before,
    .ant-carousel .slider__dots_accent .slick-dots li button,
    .ant-carousel .slick-dots ul li button {
      background-color: $color-white;
    }

    .footer,
    .btn_primary,
    .collapse__header-icon {
      background-color: $color-black;
    }

    .accessibility__btn_color1,
    .card__header-title {
      color: $color-black;
    }

    .collapse__item {
      box-shadow: 0 0 0 2px $color-white;
    }
  }

  &-color3 {
    background-color: #9dd1ff;
    color: #063462;

    .ant-layout,
    .content {
      background-color: #9dd1ff;
    }

    a,
    a:hover,
    a:focus,
    .card__body,
    .list-cards__item {
      color: #063462;
      border-color: #063462;
    }

    .page__header,
    .footer {
      border-color: #063462;
    }

    header,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .page__header-body,
    .list-ordered__item:before,
    .page__block_cover,
    .page__block_cover .title,
    .footer__copyright,
    .page-list__item .list__item-title:hover,
    .footer__consult-label,
    .footer__socials-title,
    .list-cards__item,
    .steps__item:before,
    .page__block_cover .ant-carousel {
      color: #063462;
    }

    .page__header:after,
    .list-ordered__item:before,
    .page__block_cover::before,
    .footer__copyright,
    .list__item-title:before {
      background: #9dd1ff;
    }

    .header__logo-icon,
    .btn_primary,
    .accessibility__btn-icon {
      svg {
        fill: #063462;
      }
    }

    .title_decor::before,
    .card__header::after,
    .slider-description__content:before,
    .link_decor::before,
    .collapse__header-icon,
    .ant-carousel .slider__dots_accent .slick-dots li button,
    .ant-carousel .slick-dots ul li button {
      background-color: #063462;
    }

    .footer,
    .btn_primary {
      background-color: #9dd1ff;
    }

    .collapse__item {
      box-shadow: 0 0 0 2px #063462;
    }

    .card__header-title {
      color: #9dd1ff;
    }
  }

  .accessibility-panel {
    font-size: 18px;
  }
}
